import React from "react";
import { Fragment } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoffee } from "@fortawesome/free-solid-svg-icons";

// style
import "../css/PorQueNosotros.css";

// images
import USER from "../media/pictures/user.jpeg";

import CLIENT1 from "../media/pictures/client1.jpeg";
import CLIENT2 from "../media/pictures/client2.jpeg";
import CLIENT3 from "../media/pictures/client3.jpeg";
import CLIENT4 from "../media/pictures/client4.jpeg";
import CLIENT5 from "../media/pictures/client5.jpeg";
import CLIENT6 from "../media/pictures/client6.jpeg";
import CLIENT7 from "../media/pictures/client7.jpeg";
import CLIENT8 from "../media/pictures/client8.jpeg";
import CLIENT9 from "../media/pictures/client9.png";
import CLIENT10 from "../media/pictures/client10.png";
import CLIENT11 from "../media/pictures/client11.png";
import CLIENT12 from "../media/pictures/client12.png";
import CLIENT13 from "../media/pictures/client13.png";
import CLIENT14 from "../media/pictures/CONTREM.jpg";
import CLIENT15 from "../media/pictures/osmanantunez.jpg";
import CLIENT16 from "../media/pictures/LOGISTICS.jpg";
import CLIENT17 from "../media/pictures/ANFIX.jpg";
import CLIENT18 from "../media/pictures/ALIANZACREATIVA.jpg";
import CLIENT19 from "../media/pictures/NAILTIQUE.jpg";
import CLIENT20 from "../media/pictures/MATCHCAFE.jpg";
import CLIENT21 from "../media/pictures/InMedic.jpg";

const PorQueNosotros = () => {
  return (
    <Fragment>
      <div class="ita-wecont" id="porque-nosotros">
        <div class="ita-srv-tittle">
          <div class="ita-srv-span">
            <span>¿Por qué nosotros?</span>
          </div>
          <div class="tcircles">
            <div className="circles">
              <div className="small-green-circle"></div>
              <div className="small-green-circle"></div>
              <div className="small-green-circle"></div>
              <div className="small-green-circle"></div>
              <div className="small-green-circle"></div>
              <div className="small-green-circle"></div>
            </div>
          </div>
        </div>

        <div class="final-sec">
          <div class="ita-wesec1">
            <div class="first-sect">
              <div>
                <h2>AÑOS DE EXPERIENCIA</h2>
                <div class="ita-we-info-text">
                  <p>
                    14 años de experiencia siendo aliados en tu negocio,
                    comprometiéndonos y siendo responsables en el cumplimineto
                    oportuno de las obligaciones fiscales, de seguridad social,
                    impuestos locales y avisos relacionados con la Ley
                    Antilavado.
                  </p>
                </div>
              </div>
              <div>
                <div class="ita-we-info-text">
                  <h2>EQUIPO QUE CONFORMA</h2>
                  <p>
                    Servicios contables ITA está conformado por excelentes
                    profesionistas especializados en contabilidad. Nuestro
                    equipo está conformado por:
                  </p>
                </div>
              </div>
            </div>
            <div>
              <img src={USER}></img>
              <h2>Mariza Trujillo ITA</h2>
            </div>
            <div>
              <div class="ita-historia">
                <h2>QUÉ NOS DIFERENCIA</h2>
                <div class="ita-we-info-text">
                  <p>
                    Somos un despacho con atención profesional e inmediata que
                    brinda propuestas a la medida, lo que permite crear una
                    alianza despacho-cliente, convirtiéndonos en socios
                    estratégicos y de apoyo para generar no solo el cumplimiento
                    de obligaciones fiscales, si no soluciones con visiones a
                    futuro que permitan el crecimientos y la continuación de la
                    empresa de manera contable, financiera, administrativa y
                    fiscal.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="ita-srv-tittle">
          <div class="ita-srv-span">
            <span>Nuestros clientes</span>
          </div>
          <div class="tcircles">
            <div className="circles">
              <div className="small-green-circle"></div>
              <div className="small-green-circle"></div>
              <div className="small-green-circle"></div>
              <div className="small-green-circle"></div>
              <div className="small-green-circle"></div>
              <div className="small-green-circle"></div>
            </div>
          </div>
        </div>

        <div class="final-sec2">
          <div class="ita-wesec3">
            <div>
              <div class="ita-we-info-text">
                <img src={CLIENT1}></img>
              </div>
              <div class="ita-we-info-text">
                <img src={CLIENT2}></img>
              </div>
              <div class="ita-we-info-text">
                <img src={CLIENT3}></img>
              </div>
            </div>
            <div>
              <div class="ita-we-info-text">
                <img src={CLIENT4}></img>
              </div>
              <div class="ita-we-info-text">
                <img src={CLIENT5}></img>
              </div>
              <div class="ita-we-info-text">
                <img src={CLIENT6}></img>
              </div>
            </div>
            <div>
              <div class="ita-we-info-text">
                <img src={CLIENT7}></img>
              </div>
              <div class="ita-we-info-text">
                <img src={CLIENT8} class="fin-logo"></img>
              </div>
              <div class="ita-we-info-text">
                <img src={CLIENT9} class="fin-logo"></img>
              </div>
            </div>
            <div>
              <div class="ita-we-info-text">
                <img src={CLIENT10}></img>
              </div>
              <div class="ita-we-info-text">
                <img src={CLIENT11} class="fin-logo"></img>
              </div>
              <div class="ita-we-info-text">
                <img src={CLIENT13} class="fin-logo"></img>
              </div>
            </div>
            <div>
              <div class="ita-we-info-text">
                <img src={CLIENT12}></img>
              </div>
              <div class="ita-we-info-text">
                <img src={CLIENT14}></img>
              </div>
              <div class="ita-we-info-text">
                <img src={CLIENT15}></img>
              </div>
            </div>
            <div>
              <div class="ita-we-info-text">
                <img src={CLIENT16}></img>
              </div>
              <div class="ita-we-info-text">
                <img src={CLIENT17}></img>
              </div>
              <div class="ita-we-info-text">
                <img src={CLIENT18}></img>
              </div>
            </div>
            <div>
              <div class="ita-we-info-text">
                <img src={CLIENT19}></img>
              </div>
              <div class="ita-we-info-text">
                <img src={CLIENT20}></img>
              </div>
              <div class="ita-we-info-text">
                <img src={CLIENT21}></img>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default PorQueNosotros;
